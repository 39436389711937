<template>
  <CRow>

    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-3">
              <CHeaderNav>
                <CHeaderNavItem class="px-3">
                  <button
                    class="c-header-nav-btn"
                    @click="openSidebarEvent('callEventFilter')"
                  >
                    <CIcon v-if="!loading" size="lg" name="cil-filter" class="mr-2"/>
                  </button>
                </CHeaderNavItem>
              </CHeaderNav>
            </div>
            <div class="col-9 text-right">
              Tüm Kayıtlar:
              <input type="checkbox" style="height: 15px; width: 15px" v-model="allResult" @change="callEventLogs"/>&nbsp;&nbsp;&nbsp;&nbsp;
              <CButton color="dark" @click="showDescription">Açıklama</CButton>&nbsp;&nbsp;
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            :items="callEvents"
            :fields="fields"
            :items-per-page="10"
            :loading="loading"
            hover
            pagination
            clickable-rows
            @row-clicked="rowClicked"
            @row-double-clicked="findOrder"
            v-on:refresh="filterCallEventLogs"
          >
            <template #show_details="{item, index}">
              <td class="py-2">

                <CDropdown
                  toggler-text="İşlemler" color="info"
                  class="m-2"
                >
                  <div role="group">
                    <CDropdownItem @click="goToOrdersPage(item)">Siparişleri</CDropdownItem>
                    <CDropdownItem @click="goToCustomerPage(item)">Müşteri Bilgisi</CDropdownItem>
                    <CDropdownItem @click="beginCall(item)">Müşteriyi Ara</CDropdownItem>
                  </div>
                </CDropdown>

              </td>
            </template>
            <template #createTime="{item}">
              <td> {{ item.createTime && dateFormat(item.createTime) }}</td>
            </template>
            <template #recordingPresent="{item}">
              <td>
                <CIcon v-if="item.recordingPresent" class="green"
                       name="cil-check-alt"/>
                <CIcon v-else name="cil-x" class="red"/>
              </td>
            </template>
            <template #answered="{item}">
              <td>
                <CIcon v-if="item.answered" class="green"
                       name="cil-check-alt"/>
                <CIcon v-else name="cil-x" class="red"/>
              </td>
            </template>
          </CDataTable>
          <CModal
            title="Bilgilendirme"
            color="info"
            :show.sync="infoModal"
          >
            <span v-html="infoModalTitle"></span>
            <CButtonClose @click="infoModal = false" class="text-white"/>

            <template #footer>
              <CButton @click="infoModal = false" color="dark">Kapat</CButton>
            </template>
          </CModal>
          <CModal
            :show.sync="jspModal"
            :close-on-backdrop="false"
            :centered="true"
            :loading="loading"
            color="info"
            size="xl"
          >
            <div class="container" id="printableArea">
              <iframe width="1110" height="730" frameborder="0" name="responseFrame" id="responseFrame"></iframe>
            </div>
            <template #header>
              <h6 class="modal-title">{{ jspModalTitle }}</h6>
              <CButtonClose @click="jspModal = false" class="text-white"/>
            </template>
            <template #footer>
              <CButton @click="jspModal = false" color="dark">Kapat</CButton>
            </template>
          </CModal>
        </CCardBody>
      </CCard>
    </CCol>

    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterCallEventLogs"
      :module="module"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />
  </CRow>
</template>

<script>
import moment from "moment";
import FilterSidebar from '../components/filterSidebar'
import { prepareFilterParams } from "@/helpers/custom"

export default {
  name: 'CallEventLog',
  components:{
     FilterSidebar
  },
  data() {
    return {
      module: 'social',
      allResult: false,
      lastPage: {},
      openSidebar: false,
      filterForm: 'callEventFilter',
      filterParams: {},
      lastItem: {},
      activePage: 1,
      infoModal: false,
      infoModalTitle: '',
      jspModal:false,
      jspModalTitle:"",
      fields: [
        {
          key: 'show_details',
          label: 'İşlemler',
          _style: 'font-size:12px'
        },
        {key: 'createTime', label: 'Kayıt_Tarihi', _style: 'font-size:12px'},
        {key: 'eventType', label: 'Olay_Tipi', _style: 'font-size:12px'},
        {key: 'domainId', label: 'Bulut_Santral_Id', _style: 'font-size:12px'},
        {key: 'callerIdNumber', label: 'Arayan_Numara', _style: 'font-size:12px'},
        {key: 'outboundCallerIdNumber', label: 'Arayanın_Dış_Numarası', _style: 'font-size:12px'},
        {key: 'destinationNumber', label: 'Aranan_Numara', _style: 'font-size:12px'},
        {key: 'dialedUser', label: 'Aranan_Kişinin_Dahili_Numarası',_style: 'font-size:12px'},
        {key: 'connectedUser', label: 'Bağlanan_Dahili(telefonu_açan)',_style: 'font-size:12px'},
        {key: 'callUuid', label: 'Çağrı_uuid',_style: 'font-size:12px'},
        {key: 'startStamp', label: 'Çağrı_Başlangıcı',_style: 'font-size:12px'},
        {key: 'answerStamp', label: 'Çağrı_Cevaplama_Zamanı',_style: 'font-size:12px'},
        {key: 'endStamp', label: 'Çağrının_Sonlandığı_Zaman',_style: 'font-size:12px'},
        {key: 'duration', label: 'Konuşma_Süresi',_style: 'font-size:12px'},
        {key: 'recordingPresent', label: 'Çağrının_Ses_Kaydı',_style: 'font-size:12px'},
        {key: 'answered', label: 'Kaçan_Çağrı',_style: 'font-size:12px'},
        {key: 'queue', label: 'Kuyruk/Çağrı_Grubu_No',_style: 'font-size:12px'},
        {key: 'queueWaitDuration', label: 'Kuyrukta_Bekleme_Süresi',_style: 'font-size:12px'},
        {key: 'sipHangupDisposition', label: 'Çağrıyı_Kim_Kapattı',_style: 'font-size:12px'},
        {key: 'hangupCause', label: 'Çağrının_Kapanma_Sebebi',_style: 'font-size:12px'},
        {key: 'failureStatus', label: 'Hata_Kodu',_style: 'font-size:12px'},
        {key: 'failurePhrase', label: 'Hata_Mesajı',_style: 'font-size:12px'}
      ],
      filters: [
        {
          type: 'string',
          dataIndex: 'callerIdNumber',
          field: 'callerIdNumber'
        },
        {
          type: 'string',
          dataIndex: 'destinationNumber',
          field: 'destinationNumber',
        },{
          type: 'date',
          dataIndex: 'startTime',
          field: 'createTime',
          comparison: 'gt'
        },{
          type: 'date',
          dataIndex: 'endTime',
          field: 'createTime',
          comparison: 'lt'
        }
      ],

    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
    reloadParams () {
      this.onTableChange()
    }
  },
  computed: {
    reloadParams () {
      return [
        this.activePage
      ]
    },
    callEvents() {
      return this.$store.getters.callEvents
    },
    loading() {
      return this.$store.getters.socialLoading
    }
  },
  methods: {
    openSidebarEvent( form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
    },
    dateFormat(date){
      return moment(date).format('DD.MM.YYYY HH:mm:ss')
    },
    
    async filterCallEventLogs(params) {
      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)
      if(!formData){
        formData = new FormData()
      }

      formData.append("allUsers",this.allResult);

      await this.$store.dispatch('callEvent_list', formData)

      if(this.openSidebar){
        await this.openSidebarEvent()
      }
    },
    showDescription() {
      this.infoModal = true;
      this.infoModalTitle ="<b>event_type:</b> Olay tipi. ringing, answer, hangup veya user_hangup döner.<br/>" +
        "<b>domain_id:</b> Bulutsantral ID’si.<br/>" +
        "<b>direction:</b> Çağrının yönü. internal, inbound yada outbound döner.<br/>" +
        "<b>caller_id_number:</b> Arayan numara.<br/>" +
        "<b>outbound_caller_id_number:</b> Arayanın dış numarası.<br/>" +
        "<b>destination_number:</b> Aranan numara.<br/>" +
        "<b>disaled_user¹:</b> Aranan kişinin dahili numarası.<br/>" +
        "<b>connected_user¹:</b> Bağlanan (telefonu açan) dahili.<br/>" +
        "<b>call_uuid:</b> Çağrının uuid'si.<br/>" +
        "<b>start_stamp:</b> Çağrının başladığı zaman.<br/>" +
        "<b>answer_stamp:</b> Çağrının cevaplandığı zaman.<br/>" +
        "<b>end_stamp:</b> Çağrının sonlandığı zaman.<br/>" +
        "<b>duration:</b> Konuşma süresi.<br/>" +
        "<b>recording_present:</b> Çağrının ses kaydının olup olmadığı. true yada false döner.<br/>" +
        "<b>answered:</b> Kaçan çağrı tespiti için kullanılır. Aranan taraf çağrıyı kabul ettiyse true döner.<br/>" +
        "<b>queue:</b> Kuyruğun veya Çağrı grubunun numarası.<br/>" +
        "<b>queue_wait_duration:</b> Kuyrukta bekleme süresi.<br/>" +
        "<b>sip_hangup_disposition:</b> Çağrının kimin tarafından kapatıldığı. caller ya da callee olarak döner.<br/>" +
        "<b>hangup_cause:</b> Çağrının kapanma sebebi.<br/>" +
        "<b>failure_status:</b> Çağrı başarısız ise hata kodu.<br/>" +
        "<b>failure_phrase:</b> Çağrı başarısız ise hata mesajı."
    },
    rowClicked(item) {
      this.lastItem = item;
    },
    async findOrder() {
      var formData = new FormData();
      formData.append("callingNumber",this.lastItem.callerIdNumber);
      await this.$store.dispatch('ordersByCallerId', formData);
      if(this.$store.getters.socialStatus.success) {
        let params = {
          orderId: this.$store.getters.socialStatus.orderId,
          emailAddress: this.$store.getters.socialStatus.emailAddress
        }
        await this.$store.dispatch('encryptemail', params);
        if(this.$store.getters.encrytedEmail.success) {
          this.getOrderDetail(this.$store.getters.socialStatus.orderId,this.$store.getters.encrytedEmail.encrytedEmail)
        }
      }
    },
    async getOrderDetail(orderId, encryptedEmail) {
      document.getElementById('responseFrame').contentWindow.document.write("");
      this.jspModal = true;
      this.jspModalTitle = "Sipariş Detayı : "+orderId;
      const params = {
        "orderId": orderId,
        "encryEmail": encryptedEmail
      };
      await this.$store.dispatch('orderDetail', params);
      if(this.$store.getters.orderDetailCaller){
        var doc = document.getElementById('responseFrame').contentWindow.document;
        doc.open();
        doc.write(this.$store.getters.orderDetailCaller);
        doc.close();
      }
    },
    async goToOrdersPage(data) {
      var formData = new FormData();
      formData.append("callingNumber",data.callerIdNumber);
      await this.$store.dispatch('ordersByCallerId', formData);
      if(this.$store.getters.socialStatus.success) {
        let routeData = this.$router.resolve({name: 'Orders', query: {emailAddress: this.$store.getters.socialStatus.emailAddress}});
        window.open(routeData.href, '_blank');
      }
    },
    async goToCustomerPage(data){
      var formData = new FormData();
      formData.append("callingNumber",data.callerIdNumber);
      await this.$store.dispatch('customersByCallerId', formData);
      if(this.$store.getters.socialStatus.success) {
        let routeData = this.$router.resolve({name: 'Customers', query: {customerId: this.$store.getters.socialStatus.customerId}});
        window.open(routeData.href, '_blank');
      }
    },
    async beginCall(data) {
      let params = {
        destination: data.callerIdNumber
      }
      await this.$store.dispatch('beginCall', params);
      let toast
      if(this.$store.getters.socialStatus.success) {
        toast = {
          msg: 'Müşteri Aranıyor...',
          header: 'Aranıyor...',
          color: 'success'
        }
      }else{
        toast = {
          msg: 'Arama yapılamadı. (Müşteri arama dahiliniz tanımlı olmayabilir.)',
          header: 'Hata',
          color: 'danger'
        }
      }
      vm.$store.commit('showToast', toast)

    },
    async callEventLogs() {
      this.filterCallEventLogs(this.filterParams);

    },
  },
  created() {
    this.filterCallEventLogs(this.filterParams);
  }
}
</script>


